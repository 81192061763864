(<template>
  <page-data-section :title="sectionTitle"
                     :progress="progressActive"
                     class="ent-settings">
    <div class="ent-settings__content-wrapper">
      <div class="ent-settings__filtration">
        <sk-select v-if="hasDepartments"
                   :items-list="departmentsFilterList"
                   :default-value="departmentFilterText"
                   :preselected-value="departmentId"
                   class="ent-settings__filter"
                   @csvaluechanged="changeDepartment" />
        <button v-if="canChange"
                class="sk-btn sk-btn--default ent-settings__add-btn"
                @click="openAddModal">{{ $gettext('New address') }}</button>
      </div>
      <ent-setting-table :items-list="addresses"
                         :cols-list="colsList"
                         :can-change="canChange"
                         @edititem="editAddress"
                         @deleteitem="confirmRemoval" />
    </div>
  </page-data-section>
</template>)

<script>
  import {mapState} from 'vuex';
  import DepartAddrSettingPrototype from '@/prototypes/DepartAddrSettingPrototype';

  export default {
    asyncData({store}) {
      let promiseArr = '';
      if (!store.state.EnterpriseStore.addresses) {
        promiseArr = [...promiseArr, store.dispatch('EnterpriseStore/getAddresses')];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    extends: DepartAddrSettingPrototype,
    computed: {
      ...mapState('EnterpriseStore', {
        addresses: (state) => state.addresses || []
      }),
      sectionTitle() {
        return `${this.$gettext('Addresses')}${this.$gettext('<span class="title-note">You can associate an address with each department under the menu item "Departments"</span>')}`;
      },
      colsList() {
        return [{
          key: 'line1',
          name: this.$gettext('Address line 1')
        }, {
          key: 'line2',
          name: this.$gettext('Address line 2')
        }, {
          key: 'postcode',
          name: this.$gettext('Postcode')
        }, {
          key: 'city',
          name: this.$gettext('City')
        }, {
          key: 'county',
          name: this.$gettext('County')
        }, {
          key: 'country',
          name: this.$gettext('Country')
        }, {
          key: 'departmentsNumber',
          name: this.$gettext('Nr. of departments')
        }];
      }
    },
    methods: {
      openAddModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-address-modal',
          width: 410,
          data: {
            title: this.$gettext('Add new address'),
            context: this
          }
        });
      },
      editAddress(address) {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-address-modal',
          width: 410,
          data: {
            title: this.$gettext('Edit address'),
            currentAddress: address,
            context: this
          }
        });
      },
      confirmRemoval(addressId) {
        if (this.canChange) {
          this.$store.commit('ModalStore/setModal', {
            component: 'remove-confirm',
            classes: ['remove-confirm'],
            width: 410,
            data: {
              title: this.$gettext('Delete address?'),
              text: this.$gettext('Are you sure you want to delete this address from your business account?'),
              btnTexts: {
                removeBtnText: this.$gettext('Delete'),
                cancelBtnText: this.$gettext('Cancel')
              },
              id: addressId,
              context: this
            }
          });
        }
      },
      removeItem(id) {
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/removeAddress', id).then(() => {
          this.departmentId = '';
          this.progressActive = false;
        }).catch((error) => {
          this.progressActive = false;
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        });
      },
      filterData() {
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/getAddresses', this.departmentId).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>

<style scoped src="@assets/css/enterprise_settings_page.css"></style>
